import { FetchReadResourceTypes } from '../types';
import { FetchResoureError, ErrorMessageCommon } from '../errors';

export function handleResolvePromise<Resource>(promise: Promise<Resource>): FetchReadResourceTypes<Resource> {
  let resource: Resource | null = null;
  let errorState: FetchResoureError | null = null;

  const suspender = promise
    .then((response: Resource) => {
      resource = response;
   })
   .catch((error: unknown) => {
    if (typeof error === 'string') {
      errorState = new FetchResoureError(error);
    } else if (error instanceof Error) {
      errorState = new FetchResoureError(error.message);
    } else {
      errorState = new FetchResoureError(ErrorMessageCommon.unknown);
    }
   });
    
  return {
    read() {
      if (errorState) {
        throw errorState;
      } else if (resource === null) {
        throw suspender;
      } else {
        return resource;
      }
    }
  };
}
