import { createContext } from 'react';

import type { FileHandleContextTypes } from './types';

const FileHandleContext = createContext<FileHandleContextTypes | null>(null);

FileHandleContext.displayName = 'FileHandleContext';


const FileSystemDirectoryHandleContext = createContext<FileSystemDirectoryHandle | null>(null);

FileSystemDirectoryHandleContext.displayName = 'FileSystemDirectoryHandleContext';

export { FileSystemDirectoryHandleContext } 

export default FileHandleContext;




