import { z } from 'zod';

import { ZodPrismaNumberQueryObject } from './common';

export const ZodCreateApplication = z.object({
  branchId: z.coerce.number(),
  playlistId: z.coerce.number(),
}).strict();

export const ZodUpdateApplication = z.object({
  playlistId: z.coerce.number().optional(),
  password: z.coerce.string().optional(),
}).strict();

export const ZodFilterApplication = z.object({
  id: ZodPrismaNumberQueryObject.optional(),
  branchId: ZodPrismaNumberQueryObject.optional(),
  accountId: ZodPrismaNumberQueryObject.optional(),
});

export const ZodUpdateActiveApplication = z.object({
  active: z.boolean(),
}).strict();

export const ZodLoginApplication = z.object({
  appId: z.coerce.number(),
  password: z.coerce.string(),
}).strict();

export const ZodUpdateApplicationData = z.object({
  playlistId: z.coerce.number().optional(),
  password: z.coerce.string().optional(),
  active: z.boolean().optional(),
}).strict();

export const ZodUpdateSyncAppData = z.object({
  version: z.coerce.number(),
}).strict();