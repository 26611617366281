import { Grid, Button, Stack, Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type ShowErrorProps = {
  error: Error | null;
  resetError: () => void;
}
export default function ShowError(props: ShowErrorProps) {
  const { error, resetError } = props;
  return (
    <Grid
      container
      component='main'
      sx={{ height: '100vh' }}
      justifyContent='center'
      alignItems='center'
    >
      <Stack spacing={4}>
        <Button onClick={resetError}>
          다시시도하기 &nbsp;
          (계속 문제가 발생할 경우 관리자에게 문의하십시오)
        </Button>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='error-boundary'
            id='error-boundary-id'
          >
            <Typography>
              문제가 발생했습니다.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {error ? error.message : ''}
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Grid>
  );
}