import { useState, useEffect } from 'react';

import type { AppProviderClass } from '../provider';
import { ErrorMessageForGetLocalVideoFiles } from '../errors';

type Props = {
  appProvider?: AppProviderClass;
  fileSystemDirectoryHandle?: FileSystemDirectoryHandle;
}
export function useGetLocalVideoFiles({ appProvider, fileSystemDirectoryHandle }: Props): [boolean, File[], Error | undefined] {
  const [fetched, setFetched] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const fetchLocalFiles = async (appProvider: AppProviderClass, fileSystemDirectoryHandle: FileSystemDirectoryHandle) => {
      try {
        const files = await appProvider.getLocalVideoFiles(fileSystemDirectoryHandle);
        setFiles(files);
        setFetched(true);
      } catch (error: unknown) {
        setError(new Error(ErrorMessageForGetLocalVideoFiles['failed to read file']));
      }
    }

    if (appProvider && fileSystemDirectoryHandle) {
      fetchLocalFiles(appProvider, fileSystemDirectoryHandle);
    }
  }, [appProvider, fileSystemDirectoryHandle]);

  return [fetched, files, error];
}