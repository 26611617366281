import { useContext, useEffect, useState } from 'react';

import { AppProviderContext, AppProviderClass } from '../provider';

export function useAppProvider(): AppProviderClass | undefined {
  const provider = useContext(AppProviderContext);
  const [appProvider, setAppProvider] = useState<AppProviderClass>();

  useEffect(() => {
    async function init(provider: AppProviderClass) {
      setAppProvider(provider);
    }

    if (provider) {
      init(provider);
    }
  }, [provider]);

  return appProvider;
}