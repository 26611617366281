export class FetchResoureError extends Error {
  // public code: string;

  constructor(message: string) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    this.name = 'FetchResoureError';
    this.message = message;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchResoureError);
    }
  }
}
  