import React from 'react';
import { Grid, Button, Stack, Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type ErrorBoundaryProps = {
  fallback?: Readonly<React.ReactElement>;
  children?: Readonly<React.ReactNode>;
}

type ErrorBoundaryState = {
  hasError: boolean;
  message: string;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, message: '' };

    this.handleResetError = this.handleResetError.bind(this);
  }

  // 생명주기 메서드인 static getDerivedStateFromError() 와 componentDidCatch() 중 하나 (혹은 둘 다)를 정의하면 클래스 컴포넌트 자체가 에러 경계가 됩니다. 에러가 발생한 뒤에 폴백 UI를 렌더링하려면 static getDerivedStateFromError()를 사용하세요. 에러 정보를 기록하려면 componentDidCatch()를 사용하세요.
  static getDerivedStateFromError(error: Error) {
    const message = error.message || '알수 없음';
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // 에러 리포팅 서비스에 에러를 기록할 수도 있습니다.
    // logErrorToMyService(error, errorInfo);
  }

  handleResetError() {
    this.setState({
      hasError: false,
      message: '',
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          container
          component='main'
          sx={{ height: '100vh' }}
          justifyContent='center'
          alignItems='center'
        >
          <Stack spacing={4}>
            <Button onClick={this.handleResetError}>
              다시시도하기 &nbsp;
              (계속 문제가 발생할 경우 관리자에게 문의하십시오)
            </Button>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='error-boundary'
                id='error-boundary-id'
              >
                <Typography>
                  문제가 발생했습니다.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.message}
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Grid>
      );
    }

    return this.props.children;
  }
}