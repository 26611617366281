import { VideoEntityExtendsType } from '@guardianslab/types';

import type { AppVideoFileType } from '../../types';
import { LocalVideoFileStatus, LOCAL_FILE_PREFIX } from '../../constants';
import { getVideoIdFromFileName } from '../../utils/file-systems';

function createVideoPrefix(id: string | number) {
  return `${LOCAL_FILE_PREFIX}-${id}`;
}

function getUnRegisterFileList(list: VideoEntityExtendsType[], fileRecord: Record<string, File>): AppVideoFileType[] {
  const registeredVideoRecord: Record<string, VideoEntityExtendsType> = list.reduce((acc, current) => {
    const { id } = current;
    acc[createVideoPrefix(id)] = current;
    return acc;
  }, {} as Record<string, VideoEntityExtendsType>);

  const unRegisteredKey = Object.keys(fileRecord).filter((key: string) => {
    if (registeredVideoRecord[key]) {
      return false;
    }
    return true;
  }).map((key, index) => {
    const file = fileRecord[key];
    return {
      id: `${LocalVideoFileStatus.unregistered}-${index + 1}`,
      order: undefined,
      runningTime: undefined,
      title: file.name,
      type: file.type,
      size: file.size,
      flag: LocalVideoFileStatus.unregistered,
      file,
    }
  });

  return unRegisteredKey;
}

function transformFilesToRecord(files: File[]): Record<string, File> {
  const videoFileRecord: Record<string, File> = {};

  return files.reduce((acc, current) => {
    const { name } = current
    const key = getVideoIdFromFileName(name);
    acc[key] = current;
    return acc;
  }, videoFileRecord);
}

function getRegistredFileList(list: VideoEntityExtendsType[], fileRecord: Record<string, File>): AppVideoFileType[] {
  return list.map((video) => {
    const {
      id,
      playlistOrder,
      title,
      type,
      size,
      runningTime
    } = video;

    const localFileId = createVideoPrefix(id);
    const isDownloaded = fileRecord[localFileId];
    
    return {
      id: `${LocalVideoFileStatus.registered}-${id}`,
      videoId: id,
      order: playlistOrder,
      runningTime: runningTime,
      title,
      type,
      size,
      flag: isDownloaded ? LocalVideoFileStatus.downloaded : LocalVideoFileStatus.undownloaded,
      file: fileRecord[localFileId],
    }
  });
}

// registred & download만
export function getPlaylistVideo(list: AppVideoFileType[]): AppVideoFileType[] {
  const playlist = list.filter((videoFile) => videoFile.flag === LocalVideoFileStatus.downloaded)
    // .map((item) => {
    //   if (item.file) {
    //     return {
    //       ...item,
    //       src: window.URL.createObjectURL(item.file),
    //     }
    //   }
    //   return item;
    // })
    .sort((prev, curr) => {
      if (prev.order !== undefined && curr.order !== undefined && prev.order !== null && curr.order !== null) {
        const value = prev.order - curr.order;
        if (value > 0) {
          return 1;
        } else if (value < 0) {
          return -1;
        }
      
        return 0; 
      }
      return 0;
    });
  return playlist as AppVideoFileType[];
}

// export function revokeObjectURL(list: AppVideoFileType[]) {
//   list.forEach((item) => {
//     window.URL.revokeObjectURL(item.src);
//   });
// }

// list -> video & playlist data
// file -> video File
export function getVideoFileList(list: VideoEntityExtendsType[], files: File[]): AppVideoFileType[] {
  const currentLocalFile = transformFilesToRecord(files);

  const registerVideoFileList = getRegistredFileList(list, currentLocalFile);
  const unRegisterVideoFileList = getUnRegisterFileList(list, currentLocalFile);

  return [
    ...registerVideoFileList,
    ...unRegisterVideoFileList,
  ];
}

// download 할께 있을 경우에만 Sync 버튼 활성화!!
export function checkNeedSync(videoFiles: AppVideoFileType[]): boolean {
  return videoFiles.some((videoFile) => videoFile.flag === LocalVideoFileStatus.undownloaded);
}