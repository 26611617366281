import { Grid, CircularProgress } from '@mui/material';

export default function Loader() {
  return (
    <Grid
      container
      component='main'
      sx={{ height: '100vh' }}
      justifyContent='center'
      alignItems='center'
    >
      <CircularProgress />
    </Grid>
  );
}