import axios, { AxiosResponse, type AxiosInstance, type AxiosProgressEvent } from 'axios';
import { AppStatusType, SyncAppEntity, VideoPlaylistEntity, BranchEntity } from '@guardianslab/types';

import { createAxiosInstance } from '../axios';
// import { AppLog } from '../log;;
import type { LoginOptions } from '../app/types';

const addAxiosInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
  return axiosInstance;
}

export default class DataProvider {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = addAxiosInterceptors(httpClient);
  }

  private setHeader(token: string) {
    return {
      'Content-Type': 'application/json',
        'Authorization': `Basic ${token}`,
        // 'ngrok-skip-browser-warning': 'true' ngrok
    }
  }

  async chcekPasswordAndGetToken(input: LoginOptions): Promise<AppStatusType> {
    const { data } = await this.httpClient.post('/auth', input, {
      validateStatus: (status) => status < 300,
    });
    return data;
  }
    
  async checkApp(token: string): Promise<AppStatusType> {
    const { data } = await this.httpClient.get<AppStatusType>('/status', {
      headers: this.setHeader(token),
      validateStatus: (status) => status < 300,
    });
    return data;
  }

  async getDownloadVideoUrls (token: string): Promise<{ id: number; url: string; title: string; type: string; }[]> {
    const { data } = await this.httpClient.get('downloads', {
      headers: this.setHeader(token),
    });
    return data;
  }

  async getDownloadVideoIdUrl (token: string, id: number | string): Promise<{ id: number; url: string; title: string; type: string; }> {
    const { data } = await this.httpClient.get(`downloads/${id}`, {
      headers: this.setHeader(token),
      timeout: 10000, // 10초
    });
    return data;
  }

  async getPlaylist (token: string, playlistId: number): Promise<VideoPlaylistEntity> {
    const { data } = await this.httpClient.get(`playlist/${playlistId}`, {
      headers: this.setHeader(token),
    });
    return data;
  }

  async getSyncApp (token: string, syncAppId: number): Promise<SyncAppEntity> {
    const { data } = await this.httpClient.get(`syncApp/${syncAppId}`, {
      headers: this.setHeader(token),
    });
    return data;
  }

  async getBranch (token: string, branchId: number): Promise<BranchEntity> {
    const { data } = await this.httpClient.get(`branch/${branchId}`, {
      headers: this.setHeader(token),
    });
    return data;
  }

  async updateAppSync (token: string, syncAppId: number, version: number): Promise<void> {
    const { data } = await this.httpClient.put(`syncApp/${syncAppId}`, {
      version,
      }, {
      headers: this.setHeader(token),
    });
    return data;
  }

  async fileDownload (url: string): Promise<AxiosResponse> {
    return await this.httpClient.get(url, {
      responseType: 'blob',
      timeout: 600000, // 180초 => 10분
    });
  }

  async s3FileUpload (token: string, resource: string, file: File): Promise<void> {
    const { data: uploadUrl } = await this.httpClient.post(resource, {
      name: file.name,
      size: file.size,
      type: file.type,
    },
    {
      headers: this.setHeader(token),
    }
    );
    const instance = axios.create();
    await instance.put(uploadUrl, file);
  }
}

export const createDataProvider = (apiUrl: string): DataProvider => {
  const httpClient = createAxiosInstance({
    baseURL: apiUrl,
    timeout: 10000,
  });

  const instance = new DataProvider(httpClient);
  return instance;
};

  


