import { Button, Grid, Stack, Divider, Typography } from '@mui/material';

type SelectDirectoryProps = {
  handleShowDirectoryPicker: () => Promise<void>;
}

export default function SelectDirectory(props: SelectDirectoryProps) {
  const { handleShowDirectoryPicker } = props;

  return (
    <Grid
      container
      component='main'
      sx={{ height: '100vh' }}
      justifyContent='center'
      alignItems='center'
    >
      <Stack
        spacing={2}
        direction='column'
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Typography>
          영상이 저장될 폴더를 선택하세요.
        </Typography>
        <Button variant='contained' onClick={handleShowDirectoryPicker}>
          Select Directory
        </Button>
      </Stack>
    </Grid>
  );
}