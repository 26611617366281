// import { DataProvider } from "../data";

let instance: AppLog;

export default class AppLog {
  private info: Array<string> = [];
  private error: Array<string> = [];
  // private dataProvider: DataProvider | undefined;

  static init(): AppLog {
    if (instance) {
      return instance;
    } else {
      instance = new AppLog();
      return instance;
    }
  }

  // static setDataProvider(dataProvider: DataProvider) {
  //   const instance = AppLog.init();
  //   instance.dataProvider = dataProvider;
  // }

  static addInfo(log: string) {
    const instance = AppLog.init();

    instance.addInfo(log);
    instance.postPorcess('info');
  }

  static addError(log: string) {
    const instance = AppLog.init();
    
    instance.addError(log);
    instance.postPorcess('error');
  }

  constructor() {
    if (instance) {
      return instance;
    }

    instance = this;
  }

  addInfo(log: string) {
    this.info.push(`Info - ${log}`);
  }

  addError(log: string) {
    this.error.push(`Error - ${log}`);
  }

  private needClear(type: 'info' | 'error'): boolean {
    if (type === 'info') {
      return this.info.length > 100 ? true : false;
    } else if (type === 'error') {
      return this.error.length > 100 ? true : false;
    }
    throw new Error('impossible');
  }

  private clear(type: 'info' | 'error') {
    if (type === 'info') {
      this.info = [];
    } else if (type === 'error') {
      this.error = [];
    }
    throw new Error('impossible');
  }

  private postPorcess (type: 'info' | 'error') {
    if (this.needClear(type)) {
      this.clear(type);
    }
  }

  // private upload() {
  //   this.dataProvider?.s3FileUpload()
  // }
}