import { FileSystemActionDefinitions } from './constants';
import { getReadWritePermission, iterateFiles } from '../../utils';
import { FileSystemActionType } from './types';
import { DirectoryTypes } from '../../types';

export const getDirectory = async (handle: FileSystemDirectoryHandle) => {
  const permission = await getReadWritePermission(handle);
  const dir: DirectoryTypes = {
    path: '.',
    handle: handle,
    entries: {},
  };

  const dirPath = await iterateFiles(handle, dir);
  return {
    permission,
    dirPath,
  }
}

export const dispatchShowDirectoryPicker = (
  dispatch: React.Dispatch<FileSystemActionType>,
  addDirectoryHandler?: (directoryHandle: FileSystemDirectoryHandle) => Promise<number>,
) => {
  return async () => {
    try {
      // https://github.com/GoogleChromeLabs/browser-fs-access
      // console.log(`supported: ${supported}`);
      if (typeof window.showOpenFilePicker !== 'function') {
        // alert('해당 브라우저는 지원하지 않는 기능입니다.');
        const root = await navigator.storage.getDirectory();
        const directoryHandle = await root.getDirectoryHandle('Xperty', { create: true });
        dispatch({
          type: FileSystemActionDefinitions.TYPE_SHOW_DIRECTORY_PICKER,
          handle: directoryHandle,
          permission: true,
        });
      } else {
        const directoryHandle = await window.showDirectoryPicker();
        const permission = await getReadWritePermission(directoryHandle);
  
        if (addDirectoryHandler) {
          await addDirectoryHandler(directoryHandle);
          dispatch({
            type: FileSystemActionDefinitions.TYPE_SHOW_DIRECTORY_PICKER,
            handle: directoryHandle,
            permission,
          });
        } else {
          dispatch({
            type: FileSystemActionDefinitions.TYPE_ACTION_ERROR,
            error: new Error('unknown'),
          });
        }
      }
    } catch (error: unknown) {
      if (error instanceof DOMException) {
        dispatch({
          type: FileSystemActionDefinitions.TYPE_ACTION_ERROR,
          error: error as DOMException,
        });
      } else {
        dispatch({
          type: FileSystemActionDefinitions.TYPE_ACTION_ERROR,
          error: new Error('unknown')
        });
      }
    }
  }
}
