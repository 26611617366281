import React from 'react';

import Loader from './Loader';
import ErrorBoundary from './ErrorBoundary';

type FetchResourceContainerProps = {
  children: React.ReactElement;
};

export default function FetchResourceContainer({ children }: FetchResourceContainerProps) {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Loader />}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
}