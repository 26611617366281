import axios from 'axios';
import type { AxiosInstance } from 'axios';

type createAxiosParams = {
  baseURL: string;
  timeout?: number;
  headers?: unknown;
}

export const createAxiosInstance = ({
  baseURL,
  timeout = 10000,
}: createAxiosParams): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    timeout,
  });

  return instance;
}
