import { useCallback, useState } from 'react';
import { Box } from '@mui/material';

import VideoPlayer from './Player';
import type { AppVideoFileType } from '../../types';

type Props = {
  loopPlay?: boolean;
  videoFiles: AppVideoFileType[];
  handleAddLog: (log: any) => void;
}
export default function VideoPlayController(props: Props) {
  const { 
    loopPlay = true,
    videoFiles = [],
    handleAddLog,
  } = props;

  const [currentVideo, setCurrentVideo] = useState<AppVideoFileType>(videoFiles[0]);
  const onEndedCallback = useCallback(() => {
    const total = videoFiles.length;
    const currentIndex = videoFiles.findIndex((file: AppVideoFileType) => file.id === currentVideo.id);
    const isLast = currentIndex === total - 1; 

    if (currentIndex >= 0) {
      if (loopPlay && isLast) {
        setCurrentVideo(videoFiles[0]);
      } else {
        setCurrentVideo(videoFiles[currentIndex + 1]);
      }
    }
  }, [loopPlay, videoFiles, currentVideo]);
  
  return (
    <Box
      component={'div'}
      width={'100vw'}
      height={'100vh'}
      style={{
        padding: 0,
        overflow: 'hidden'
      }}
    >
      <VideoPlayer
        videoFile={currentVideo}
        onEndedCallback={onEndedCallback}
        handleAddLog={handleAddLog}
      />
    </Box>    
  );
}