export enum AppStatusActionDefinitions {
  TYPE_SET_ERROR = 'TYPE_SET_ERROR',
  TYPE_APPOUT = 'TYPE_APPOUT',
  TYPE_LOADING = 'TYPE_LOADING',
  TYPE_LOADED = 'TYPE_LOADED',
  TYPE_SYNCHRONIZING = 'TYPE_SYNCHRONIZING',
  TYPE_PLAYING = 'TYPE_PLAYING',
  TYPE_OFFLINE = 'TYPE_OFFLINE',
  TYPE_SET_VIDEOFILES = 'TYPE_SET_VIDEOFILES',

  TYPE_ADD_LOG = 'TYPE_ADD_LOG',
  TYPE_CLEAR_UPLOAD_LOG = 'TYPE_CLEAR_UPLOAD_LOG',
};