import { AxiosResponse } from 'axios';

import { GrantedStatus, ReadWriteFilePermission } from './constants';
import { LOCAL_FILE_PREFIX } from '../../constants';
import { DirectoryTypes } from '../../types';

export function createVideoFileName(id: number, title: string, type: string) {
  const splitTitle = title.split('.');
  const videoType = splitTitle[splitTitle.length - 1];

  if (/mp4|webm/.test(videoType)) {
    return `${LOCAL_FILE_PREFIX}-${id}-${title}`;
  }
  
  const splitType = type.split('/');
  return `${LOCAL_FILE_PREFIX}-${id}-${title}.${splitType[splitType.length - 1]}`;
}

export function getVideoIdFromFileName(fileName: string): string {
  const value = fileName.split('-');
  return `${value[0]}-${value[1]}`;
}

// TODO
function checkFileType(file: File) {
  // if (file.size > 0 && file.type.startsWith('video/')){
  if (file.name.startsWith('logs_')) {
    return false;
  } else if (file.size < 1){
    return false;
  } else if (!/mp4|webm/.test(file.name)) {
    return false;
  }
  return true;
}

export const hasReadWritePermission = async (handle: FileSystemDirectoryHandle): Promise<boolean> => {
  // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemHandle/queryPermission
  const value = await handle.queryPermission({
    mode: ReadWriteFilePermission,
  });
  return value === GrantedStatus;
}

export const getReadWritePermission = async (handle: FileSystemDirectoryHandle): Promise<boolean> => {
  const currentPermission = await hasReadWritePermission(handle);
  if (currentPermission === false) {
    const permission = await handle.requestPermission({
      mode: ReadWriteFilePermission,
    });

    if(permission !== GrantedStatus) {
      console.error(`No permission to open file: ${handle.name}`);
      // throw new Error('No permission to open file');
      return false;
    }
  }

  return true;
}

const getFileContents = async (file: File) => {
  switch(file.type) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('loadend', () => resolve(reader.result));
        reader.readAsDataURL(file);
      });
    default:
      return file.text();
  }
}

export const getFileFromHandle = async (handle: FileSystemFileHandle) => {
  const file = await handle.getFile();
  const name = file.name;
  const contents = await getFileContents(file);
  const {type} = file; //this.getFileType(file);

  return {
    name,
    contents,
    type
  };
}

export async function brosweDirectory(
  directoryHandle: FileSystemDirectoryHandle,
  recursive: boolean = false,
): Promise<DirectoryTypes> {
  const dir = {
    path: directoryHandle.name,
    indexed: false,
    handle: directoryHandle,
    entries: {},
  };

  const directory = await iterateFiles(directoryHandle, dir, recursive);
  return directory;
}

export const iterateFiles = async (
  directoryHandle: FileSystemDirectoryHandle,
  directory: DirectoryTypes,
  recursive = false,
): Promise<DirectoryTypes> => {
  try {
    for await (const [name, handle] of directoryHandle.entries()) {
      const path = `${directory.path}/${name}`;
  
      if (directory.entries) {
        directory.entries[path] = {
          path,
          handle,
          parentHandle: directoryHandle,
          ...(handle.kind === 'directory' && {entries: {}}),
        };
        if(handle.kind === 'directory' && recursive) {
          await iterateFiles(handle, directory.entries[path], recursive);
        }
      }
    }
  
    return directory;
  } catch (error: unknown) {
    // 권한이 없거나 해당파일이 존재하지 않으면 발생할 수 있다.
    // NotAllowedError: The request is not allowed by the user agent or the platform in the current context.
    throw error;
  }
  
}

export const getVideoFiles = async (
  directory: DirectoryTypes
): Promise<File[]> => {
  try {
    if (directory.entries) {
      const promiseGetUrl = Object.entries(directory.entries)
        .filter(([path, value]) => {
          const fileHandle = value.handle as FileSystemFileHandle;
          if (fileHandle.kind === 'file') {
            return true;
          }
          return false;
        }).map(async ([key, value]): Promise<File> => {
          const fileHandle = value.handle as FileSystemFileHandle;
          const file = await fileHandle.getFile();
          return file;
        });

        const files = await Promise.all(promiseGetUrl);
        return files.filter((file) => {
          return checkFileType(file);
        });
    };
    return [];
  } catch (error: unknown) {
    throw error;
  }
}

export const getCreateFileHandle = async (handle: FileSystemDirectoryHandle, fileName: string): Promise<FileSystemFileHandle> => {
  return handle.getFileHandle(fileName, { create: true });
}

// https://developer.mozilla.org/en-US/docs/Web/API/File_System_API/Origin_private_file_system
export async function writeVideoFile(fileSystemFileHandle: FileSystemFileHandle, fetchPromise: Promise<AxiosResponse>) {
  // console.log(fileSystemFileHandle);
  // const accessHandle = await fileSystemFileHandle.createSyncAccessHandle();
  // console.log(accessHandle);
  const writable = await fileSystemFileHandle.createWritable();
  const { data } = await fetchPromise;
  if (data) {
    await writable.write(data);
    await writable.close();
  }
}

export async function writeLogFile(fileSystemFileHandle: FileSystemFileHandle, logs: any[]) {
  const writable = await fileSystemFileHandle.createWritable();
  await writable.write(JSON.stringify(logs));
  await writable.close();
}

export async function removeVideoFiles(fileHandle: FileSystemDirectoryHandle, name: string) {
  await fileHandle.removeEntry(name);
}


export async function getOpfsResource(): Promise<FileSystemDirectoryHandle>{
  const root = await navigator.storage.getDirectory();
  const directoryHandle = await root.getDirectoryHandle('Xperty', { create: true });
  return directoryHandle;
}