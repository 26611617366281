import {
  PrepareAppHome,
  // AppHomeByOpfs,
  // AppHomeByStreaming,
  AppRegister,
  IndexDBProvider,
  FileHandleProvider,
  // FetchOPFSProvider,
} from './components';

const createRoutes = () => {
  return [
    {
      path: '/login',
      withAuthorization: false,
      element: <AppRegister />,
    },
    {
      path: '/',
      withAuthorization: true,
      element: (
        <IndexDBProvider>
          <FileHandleProvider>
            <PrepareAppHome />
          </FileHandleProvider>
        </IndexDBProvider>

        // <FetchOPFSProvider>
        //   <AppHomeByOpfs />
        // </FetchOPFSProvider>

        // <AppHomeByStreaming />
      ),
    }
  ];
}
export default createRoutes;