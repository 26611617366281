export enum ErrorMessageCommon {
  'unknown' = `Unknown error`,
}

export enum ErrorMessageForIndexDB {
  'unsupport_indexeddb' = `This browser doesn't support IndexedDB`,
}

export enum ErrorMessageForGetLocalVideoFiles {
  'failed to read file' = 'Failed to read file.',
}