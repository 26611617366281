import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatBytes } from '@guardianslab/utils';
import { VideoEntityExtendsType } from '@guardianslab/types';

type VideoTableProps = {
  videos?: VideoEntityExtendsType[];
}
export default function PlaylistVideoTable(props: VideoTableProps) {
  const { videos } = props;
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label='Video'
        size='small'
      >
        <TableHead>
          <TableRow>
            <TableCell>
              ID
            </TableCell>
            <TableCell>
              Title
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell align='right'>
              Size
            </TableCell>
            <TableCell align='right'>
              RunningTime
            </TableCell>
            <TableCell align='right'>
              Order
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {videos && videos.map((video) => {

            return (
              <TableRow
                key={video.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {video.id}
                </TableCell>
                <TableCell>
                  {video.title}
                </TableCell>
                <TableCell>
                  {video.type}
                </TableCell>
                <TableCell align='right'>
                  {formatBytes(video.size)}
                </TableCell>
                <TableCell align='right'>
                  {video.runningTime}
                </TableCell>
                <TableCell align='right'>
                  {video.playlistOrder}
                </TableCell>
              </TableRow>
            );
          }
        )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}