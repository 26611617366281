import { FetchReadResourceTypes } from '../../types';
import { useIndexDB } from '../../hooks';
import { FetchResourceContainer } from '../common';
import { indexDBName } from '../../environments';
import { IndexDBContext } from '../../provider';

type Props = {
  database: FetchReadResourceTypes<IDBDatabase>;
  children: React.ReactElement;
}
function IndexDBResolver(props: Props) {
  const { database, children } = props;

  return (
    <IndexDBContext.Provider value={database.read()}>
      {children}
    </IndexDBContext.Provider>
  );
}

export default function IndexDBProvider({ children }: { children: React.ReactElement }) {
  return (
    <FetchResourceContainer>
      <IndexDBResolver
        database={useIndexDB<IDBDatabase>({ name: indexDBName })}
        children={children}
      />
    </FetchResourceContainer>
  );
}
