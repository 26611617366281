import { z } from 'zod';

export const ZodCreateBranch = z.object({
  name: z.string().min(2).max(50),
  accountId: z.coerce.number(),
}).strict();

export const ZodUpdateBranch = z.object({
  name: z.string().min(2).max(50).optional(),
  // accountId: z.coerce.number().optional(),
}).strict();