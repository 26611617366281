import { BranchEntity } from '@guardianslab/types';
import { Card, CardHeader, CardContent, TextField } from '@mui/material';

type BranchProps = {
  branch: Partial<BranchEntity>,
}

export default function Branch(props: BranchProps) {
  const { branch } = props;
  return (
    <Card>
      <CardHeader title='Branch'>
      </CardHeader>
      <CardContent>
        <TextField
          size='small'
          label='Name'
          defaultValue={branch.name}
          disabled
        />
      </CardContent>
    </Card>
  );
}