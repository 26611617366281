import { createContext } from 'react';

import { AppProviderClass } from './app';
// import { AppProviderInterface } from './types';

const AppProviderContext = createContext<AppProviderClass | null>(null);

AppProviderContext.displayName = 'AppProviderContext';

export default AppProviderContext;
