import { FileSystemStateType } from './types';

const initFileSystemsState: FileSystemStateType = {
  directory_handle: undefined,
  permission: false,
  error: undefined,
}

export function createInitialState(fileDirectoryHandle: FileSystemDirectoryHandle | undefined): FileSystemStateType {
  return {
    ...initFileSystemsState,
    directory_handle: fileDirectoryHandle || undefined,
  };
}