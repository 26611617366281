import { ErrorMessageForIndexDB } from '../../errors';
import { ReadWriteFilePermission } from '../file-systems/constants';
import { ObjectStoreName } from '../indexDB';

export function openIndexedDB (name: string, version?: number): Promise<IDBDatabase> {
  return new Promise<IDBDatabase>((resolve, reject) => {

    if (!('indexedDB' in window)) {
      reject(ErrorMessageForIndexDB.unsupport_indexeddb);
    } else {
      let db: IDBDatabase;
      const request = window.indexedDB.open(name, version);

      request.onerror = () => {
        // Do something with request.errorCode!
        reject(`IndexedDB error: ${JSON.stringify(request.error)}`);
      };

      request.onsuccess = () => {
        db  = request.result;
        // console.log(db);
        // const keyPath = 'directory_handle';
        // const objectStore = db.createObjectStore(StoreName, {
        //   autoIncrement: true
        // });
        // console.log(objectStore);
        resolve(db);
      };
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = request.result;

        // TODO
        // Create an objectStore for this database
        const store = ObjectStoreName;
        db.createObjectStore(store, { autoIncrement: true });
        // console.log(objectStore);
      }
    }
  });
}

export const createGetDirectoryHandlerFromIndexDB = (db: IDBDatabase) => {
  return (id: number): Promise<FileSystemDirectoryHandle | undefined> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(ObjectStoreName, ReadWriteFilePermission);
      const objectStore = transaction.objectStore(ObjectStoreName);

      const request = objectStore.get(id);
      request.onsuccess = (event) => {
        resolve(request.result);
      };
    });
  }
}

export const createAddDirectoryHandlerFromIndexDB = (db: IDBDatabase, indexDBId: number) => {
  return (directoryHandle: FileSystemDirectoryHandle): Promise<number> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(ObjectStoreName, ReadWriteFilePermission);
      const objectStore = transaction.objectStore(ObjectStoreName);
      const request = objectStore.put(directoryHandle, indexDBId);
      // objectStore.delete(id);
      request.onsuccess = (event) => {
        resolve(request.result as number);
      };
    });
  }
}

export const createDeleteDirectoryHandlerFromIndexDB = (db: IDBDatabase, indexDBId: number) => {
  return (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(ObjectStoreName, ReadWriteFilePermission);
      const objectStore = transaction.objectStore(ObjectStoreName);

      const request = objectStore.delete(indexDBId);

      request.onsuccess = (event) => {
        resolve();
      };
    });
  }
}