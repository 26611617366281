export default function VideoFallback() {
  return (
    <p>
      Your browser doesn't support HTML video. Here is a
      <a href='rabbit320.mp4'>
        link to the video
      </a>
      instead.
    </p>
  );
}