// https://dev.to/valentinkuharic/beginner-friendy-guide-to-error-handling-in-typescript-nodejs-expressjs-api-design-432i


export class BaseError extends Error {
  constructor(public readonly message: string) {
    super(message);
    // 이거는 무슨 원리인지 추후에 살펴보자
    Object.setPrototypeOf(this, BaseError.prototype);

    this.name = this.constructor.name;
    // Error.captureStackTrace?.(this, this.constructor);

    // console.error(`[${this.name}]: ${message}`);
    // // https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
    // if (typeof Error.captureStackTrace === 'function') {
    //   // V8
    //   // https://v8.dev/docs/stack-trace-api
    //   Error.captureStackTrace(this, this.constructor);
    // } else {
    //   this.stack = new Error(message).stack;
    // }
    // this.stack = new Error()?.stack;
    
  }
}

export class HttpError extends BaseError {
  constructor(
    public readonly message: string,
    public readonly status: number,
    public readonly body = null,
  ) {
    super(message);
    this.status = status;
  }
}
