import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { Loader } from './common';
import { useAppProvider } from '../hooks';

interface AuthorizationProps {
  children: React.ReactNode;
  redirectTo: string;
}

const AuthenticateGuard = ({
  redirectTo,
  children,
}: AuthorizationProps) => {
  const appProvider = useAppProvider();
  const isAuthProviderEmpty = appProvider === undefined;

  const isAuthenticated: Boolean = useMemo(() => {
    const res = appProvider?.isAuthenticated() || false;
    return res;
  }, [appProvider]);

  if (isAuthProviderEmpty) {
    return <Loader />;
  } else if (!isAuthProviderEmpty && isAuthenticated) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <Navigate to={redirectTo} replace={true} />
  );
};

export default AuthenticateGuard;