import type { FileSystemStateType } from './types';
import type { FileSystemActionType } from '../../actions/file-handle';
import { FileSystemActionDefinitions } from '../../actions/file-handle';

export function fileSystemReducers(
  state: FileSystemStateType,
  action: FileSystemActionType,
): FileSystemStateType {
  if (action.type === FileSystemActionDefinitions.TYPE_SHOW_DIRECTORY_PICKER) {
    return {
      ...state,
      directory_handle: action.handle,
      permission: action.permission || false,
    }
  } else if (action.type === FileSystemActionDefinitions.TYPE_ACTION_ERROR) {
    return {
      ...state,
      error: action.error,
    }
  } else if (action.type === FileSystemActionDefinitions.TYPE_INIT) {
    return {
      ...state,
      directory_handle: action.handle,
      permission: action.permission || false,
    }
  }
  return state;
}

