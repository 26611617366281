import { Card, CardContent, CardHeader, Grid, ButtonGroup,  Button } from '@mui/material';

type AppControllerProps = {
  streaming?: boolean;
  needSync?: boolean;
  canPlay: boolean;
  handleAppOut: () => void;
  handlePlay: () => void;
  // handleDownloading: () => void;
  handleSync?: () => void;
}

export default function AppController(props: AppControllerProps) {
  const {
    streaming = false,
    needSync = false,
    canPlay = false,
    handleAppOut,
    handlePlay,
    handleSync,
  } = props;

  if (streaming) {
    return (
      <Card>
        <CardHeader title='App Controller'>
        </CardHeader>
        <CardContent>
          <Grid
            container
            justifyContent='space-between'
          >
            <ButtonGroup variant='outlined'>
              <Button
                onClick={handlePlay}
                disabled={canPlay === false}
              >
                Playing
              </Button>
            </ButtonGroup>
            <Button
              variant='outlined'
              onClick={handleAppOut}
            >
              Sign Out
            </Button>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title='App Controller' />
      <CardContent>
        <Grid
          container
          justifyContent='space-between'
        >
          <ButtonGroup variant='outlined'>
            <Button
              onClick={handlePlay}
              disabled={canPlay === false}
            >
              Playing
            </Button>
            <Button
              onClick={handleSync}
              disabled={needSync === false}
            >
              Sync
            </Button>
          </ButtonGroup>
          <Button
            variant='outlined'
            onClick={handleAppOut}
          >
            Sign Out
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}