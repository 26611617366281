import { Card, CardContent, CardHeader, TextField, Stack } from '@mui/material';
import type { VideoPlaylistEntity } from '@guardianslab/types';

import type { AppVideoFileType } from '../../types';
import WithLocalVideoTable from './WithLocalVideoTable';
import PlaylistVideoTable from './PlaylistVideoTable';

type PlaylistProps = {
  streaming?: boolean;
  playlist: VideoPlaylistEntity;
  videoFiles?: AppVideoFileType[];
}

export default function Playlist(props: PlaylistProps) {
  const { playlist, videoFiles, streaming = false } = props;
  return (
    <Card>
      <CardHeader
        title='Playlist'
      />
      <CardContent>
        <Stack spacing={2}>
          <TextField
            size='small'
            label='Title'
            defaultValue={playlist.title}
            disabled
          />
          <TextField
            size='small'
            label='Video Count'
            defaultValue={playlist.video_count}
            disabled
          />
          <TextField
            size='small'
            label='Running Time'
            defaultValue={playlist.video_time}
            disabled
          />
          {/* tags */}
          { streaming === false && <WithLocalVideoTable videos={videoFiles} /> }
          { streaming === true && <PlaylistVideoTable videos={playlist.video || []} />}
        </Stack>
      </CardContent>
    </Card>
  );
}