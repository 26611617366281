import { useCallback, useEffect, useReducer } from 'react';
import type { BranchEntity, SyncAppEntity, VideoPlaylistEntity } from '@guardianslab/types';
import { CircularProgress, Backdrop, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { indexDBId } from '../../environments';
import { AppStatusActionDefinitions } from '../../actions/app';
import { createDeleteDirectoryHandlerFromIndexDB } from '../../utils/indexDB';
import { type AppStateType, type AppReducerTypes } from '../../reducers/app';
import type { AppVideoFileType } from '../../types';
import {
  appStateReducer,
  appInitState,
  createInitialState,
  useAppSelector,
 } from '../../reducers/app';
import { Loader, ShowError } from '../common';
import { AppController } from '../app-controller';
import { VideoPlayController } from '../video';
import { Branch } from '../branch';
import { SyncApp } from '../sync';
import { Playlist } from '../playlist';
import { LocalVideoFileStatus } from '../../constants';
import type { AppProviderClass } from '../../provider';

type HomeProps = {
  appProvider: AppProviderClass;
  fileSystemDirectoryHandle: FileSystemDirectoryHandle;
  videoFiles: File[];
  database: IDBDatabase;
}
export default function AppHome(props: HomeProps) {
  const {
    appProvider,
    fileSystemDirectoryHandle,
    videoFiles,
    database,
  } = props;

  const navigate = useNavigate();
  const appStatus = appProvider.getAppState();

  // const [needFetchSync, setNeedFetchSync] = useState<boolean>(appProvider.getAppStateFetchSync());
  const [appState, dispatchApp] = useReducer<AppReducerTypes, AppStateType>(
    appStateReducer,
    {
      ...appInitState,
      files: videoFiles,
    },
    createInitialState,
  );
  const appSelector = useAppSelector(appState);

  const loaded = appSelector('loaded') as boolean;
  const uploadLogs = appSelector('uploadLogs') as string[];
  const loading = appSelector('loading') as boolean;
  const appOut = appSelector('appOut') as boolean;
  const needSync = appSelector('needSync') as boolean;
  const synchronizing = appSelector('synchronizing') as boolean;
  const playing = appSelector('playing') as boolean;
  const playlist = appSelector('playlist') as VideoPlaylistEntity;
  const error = appSelector('error') as Error;

  const allVideoFiles = appSelector('allVideoList') as AppVideoFileType[];
  const videoPlaylistFiles = appSelector('videoPlaylist') as AppVideoFileType[];

  const handleAddLog = useCallback((log: any) => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_ADD_LOG,
      value: log,
    });
  }, []);

  const handleStartVideoPlay = useCallback(() => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_PLAYING,
      value: true,
    });
  }, []);

  const handleStopVideoPlay = useCallback(() => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_PLAYING,
      value: false,
    });
  }, []);

  const handleAppOut = useCallback(async () => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_APPOUT,
      value: true,
    });
    const deleteKey = createDeleteDirectoryHandlerFromIndexDB(database, indexDBId);
    await deleteKey();
    await appProvider.unRegisterApp();
    
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_APPOUT,
      value: false,
    });
    navigate('/login', { replace: true });
  }, [appProvider, database]);

  const appInit = useCallback(async () => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_LOADING,
      value: {
        loading: true,
      },
    });
    const { playlist, branch, syncApp } = await appProvider.init();
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_LOADED,
      value: {
        loaded: true,
        playlist,
        branch,
        syncApp,
      },
    });
  }, [appProvider]);

  const handleSync = useCallback(async () => {
    // TODO 로직 정리 -> business logic으로 이동
    // check sync with data
    // check sync with file

    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_SYNCHRONIZING,
      value: true,
    });

    try {
      // remove unregistred video
      const unregistredVideo = allVideoFiles.filter((video) => video.flag === LocalVideoFileStatus.unregistered);
          
      if (unregistredVideo.length > 0 && fileSystemDirectoryHandle) {
        await appProvider.removeVideoFiles(fileSystemDirectoryHandle, unregistredVideo);
      }

      const unDownloadedVideo = allVideoFiles.filter((video) => video.flag === LocalVideoFileStatus.undownloaded && video.videoId);

      if (unDownloadedVideo.length > 0 && fileSystemDirectoryHandle) {
        unDownloadedVideo.reduce(async (before, current) => {
          return before.then(() => {
            if (current && current.videoId) {
              return appProvider.getDownloadVideoId(fileSystemDirectoryHandle, current.videoId);
            }  
          })
        }, Promise.resolve())
        .then(async () => {
          const updateVideoFiles = await appProvider.getLocalVideoFiles(fileSystemDirectoryHandle);
          dispatchApp({
            type: AppStatusActionDefinitions.TYPE_SET_VIDEOFILES,
            value: updateVideoFiles,
          });

          dispatchApp({
            type: AppStatusActionDefinitions.TYPE_SYNCHRONIZING,
            value: false,
          });
        }).catch((error) => {
          throw error;
        })
      } else {
        const updateVideoFiles = await appProvider.getLocalVideoFiles(fileSystemDirectoryHandle);
        dispatchApp({
          type: AppStatusActionDefinitions.TYPE_SET_VIDEOFILES,
          value: updateVideoFiles,
        });

        dispatchApp({
          type: AppStatusActionDefinitions.TYPE_SYNCHRONIZING,
          value: false,
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatchApp({
          type: AppStatusActionDefinitions.TYPE_SET_ERROR,
          value: error,
        });
      } else {
        dispatchApp({
          type: AppStatusActionDefinitions.TYPE_SET_ERROR,
          value: new Error('unknown error'),
        });
      }
    }
    //  finally {
    //   if (appStatus) {
    //     const { syncAppId, version } = appStatus;
    //     // await appProvider.updateAppSync(
    //     //   syncAppId,
    //     //   version
    //     // );
    //     // setNeedFetchSync(false);
    //   }
    // }

    
  }, [appProvider, fileSystemDirectoryHandle, allVideoFiles, appStatus]);

  useEffect(() => {
    if (loaded === false && loading === false) {
      appInit();
    }
  }, [appInit, loaded, loading]);

  useEffect(() => {
    if (uploadLogs.length) {
      appProvider.writeVideoLogAndUpload(uploadLogs)
      .then(() => {
        dispatchApp({
          type: AppStatusActionDefinitions.TYPE_CLEAR_UPLOAD_LOG,
        });
      });
    }
  }, [uploadLogs]);

  if (loaded === false) {
    return <Loader />;
  } else if (error) {
    return <ShowError error={error} resetError={() => {
      dispatchApp({
        type: AppStatusActionDefinitions.TYPE_SET_ERROR,
        value: null,
      });
    }}/>
  }

  return (
    <Container>
      <Box>
        <AppController
          needSync={needSync}
          canPlay={videoPlaylistFiles && videoPlaylistFiles.length > 0}
          handleAppOut={handleAppOut}
          handlePlay={handleStartVideoPlay}
          handleSync={handleSync}
        />
        <Branch branch={appSelector('branch') as BranchEntity} />
        <SyncApp syncApp={appSelector('syncApp') as SyncAppEntity} />
        <Playlist
          playlist={playlist}
          videoFiles={allVideoFiles}
        />

        { appOut && 
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={appOut}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        }

        { playing &&
          <Backdrop
            sx={{ backgroundColor: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={playing}
            onDoubleClick={handleStopVideoPlay}
          >
            <VideoPlayController
              videoFiles={videoPlaylistFiles}
              handleAddLog={handleAddLog}
            />
          </Backdrop>
        }

        {
          synchronizing && 
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={synchronizing}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        }
      </Box>
    </Container>
  );
}

