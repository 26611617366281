import { useContext, useState } from 'react';

import { Loader } from '../common';
import { indexDBId } from '../../environments';
import { FetchResourceContainer } from '../../components';
import FileHandleContainer from './Container';
import { FetchReadResourceTypes } from '../../types';
import {
  useCreateGetDirectoryHandlerFromIndexDB,
  useCreateAddDirectoryHandler,
} from '../../hooks';
import { IndexDBContext, FileHandleContext } from '../../provider';

type Props = {
  database: IDBDatabase;
  fetchFileDirectoryHandle: FetchReadResourceTypes<FileSystemDirectoryHandle>;
  children: React.ReactElement;
}
function FileHandleResolver(props: Props) {
  const { fetchFileDirectoryHandle, database, children } = props;

  const [fileSystemDirectoryHandle, setFileSystemDirectoryHandle] = useState<FileSystemDirectoryHandle>();

  return (
    <FileHandleContext.Provider value={{
      addFileDirectoryHandle: useCreateAddDirectoryHandler({ database, id: indexDBId }),
      fileDirectoryHandle: fetchFileDirectoryHandle.read() || fileSystemDirectoryHandle,
    }}>
      <FileHandleContainer
        children={children}
        setFileSystemDirectoryHandle={setFileSystemDirectoryHandle}
      />
    </FileHandleContext.Provider>
  );
}

export default function FileHandleProvider({ children }: { children: React.ReactElement }) {
  const database = useContext(IndexDBContext);
  if (database) {
    return (
      <FetchResourceContainer>
        <FileHandleResolver
          database={database}
          fetchFileDirectoryHandle={useCreateGetDirectoryHandlerFromIndexDB<FileSystemDirectoryHandle>({ database, indexDBId })}
          children={children}
        />
      </FetchResourceContainer>
    );
  }

  return <Loader />;
}