import { SyncAppEntity } from '@guardianslab/types';
import { Card, CardContent, CardHeader, TextField, Stack } from '@mui/material';

type SyncProps = {
  syncApp: Partial<SyncAppEntity>,
}

export default function Sync(props: SyncProps) {
  const { syncApp } = props;
  // const label = { inputProps: { 'aria-label': 'Sync' } };
  return (
    <Card>
      <CardHeader title='App'>
      </CardHeader>
      <CardContent>
        <Stack spacing={2}>
          <TextField
            size='small'
            label='App ID'
            defaultValue={syncApp.appId}
            disabled
          />
          <TextField
            size='small'
            label='Version'
            defaultValue={syncApp.version}
            disabled
          />
          {/* <Switch 
            {...label}
            defaultChecked={syncApp.sync}
            disabled
          /> */}
        </Stack>
      </CardContent>
      
    </Card>
  );
}