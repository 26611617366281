import { useCallback, useEffect, useRef, useState } from 'react';

import VideoFallback from './Fallback';
import type { AppVideoFileType } from '../../types';
import { VideoStatus } from '../../constants';
import type { VideoLogType } from './type';

type Props = {
  videoFile: AppVideoFileType;
  onEndedCallback?: () => void;
  handleAddLog: (log: VideoLogType) => void
}
export default function VideoPlayer(props: Props) {
  const {
    videoFile,
    onEndedCallback = () => {},
    handleAddLog
  } = props;

  const [url, setUrl] = useState<string | undefined>(undefined);
  // const ref = useRef<HTMLVideoElement>(null);

  const onEnded = useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
    const now = Date.now();
    handleAddLog({
      videoId: videoFile.videoId,
      title: videoFile.title,
      type: VideoStatus.end,
      time: now,
    });
    onEndedCallback();
  }, [videoFile, onEndedCallback]);

  const onError = useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
    const now = Date.now();
    handleAddLog({
      videoId: videoFile.videoId,
      title: videoFile.title,
      type: VideoStatus.error,
      time: now,
    });
  }, [videoFile, handleAddLog]);

  // const onAbort = useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
  //   const now = Date.now();
  //   handleAddLog({
  //     videoId: videoFile.videoId,
  //     title: videoFile.title,
  //     type: VideoStatus.abort,
  //     time: now,
  //   });
  // }, [videoFile, handleAddLog]);

  // const onPause = useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
  //   const now = Date.now();
  //   handleAddLog({
  //     videoId: videoFile.videoId,
  //     title: videoFile.title,
  //     type: VideoStatus.pause,
  //     time: now,
  //   });
  // }, [videoFile, handleAddLog]);

  //  The loadeddata event is fired when the frame at the current playback position of the media has finished loading; often the first frame
  const onPlay = useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
    const now = Date.now();
    handleAddLog({
      videoId: videoFile.videoId,
      title: videoFile.title,
      type: VideoStatus.start,
      time: now,
    });
  }, [videoFile, handleAddLog]);

  useEffect(() => {
    if (videoFile.file) {
      setUrl(window.URL.createObjectURL(videoFile.file));
    }
  }, [videoFile]);

  useEffect(() => {
    return () => {
      if (url) {
        window.URL.revokeObjectURL(url);
      }
    }
  }, [url]);

  return (
    <video
      // ref={ref}
      src={url}
      // controls
      muted
      // playsInline
      style={{
        width: '100vw',
        height: '100vh',
        objectFit: 'fill'
      }}
      autoPlay={true}
      onEnded={onEnded}
      onPlay={onPlay}
      onError={onError}
      // onAbort={onAbort}
      // onPause={onPause}
      // onLoadedData={onLoadedData}
      // onLoadStart={onLoadStart}
    >
      <source
        type={videoFile.type}
      />
      <VideoFallback />
    </video>
  );
}