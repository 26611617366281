import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { apiUrl } from './environments';
import createRoutes from './routes';
import AuthenticateGuard from './components/AuthenticateGuard';
import { AppProviderClass, createAppProvider, AppProviderContext } from './provider';

function App() {
  const [appProvider, setAppProvider] = useState<AppProviderClass | null>(null);
  const routes = createRoutes();

  useEffect(() => {
    async function getAppProvider() {
      const appProvider = createAppProvider(apiUrl);
      await appProvider.doAuthenticate();
      setAppProvider(appProvider);
    }

    getAppProvider();
  }, []);

  return (
    <AppProviderContext.Provider value={appProvider}>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.withAuthorization ? (
                    // F4x9sWLVJzQ6SoKVXxH6rxNyzYG+3oQye74+q5lWeMEvmrd8GQ==
                    <AuthenticateGuard
                      redirectTo='/login'
                    >
                      {route.element}
                    </AuthenticateGuard>
                  ) : (
                    route.element
                  )
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </AppProviderContext.Provider>

    // <IndexDBContainer />
  );
}

export default App;
