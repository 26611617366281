import { useContext, useEffect, useState } from 'react';

import { FileHandleContext } from '../provider';

export function useFileSystemDirectoryHandle(): FileSystemDirectoryHandle | undefined {
  const fileHandleContext = useContext(FileHandleContext);
  const [fileHandle, setFileHandle] = useState<FileSystemDirectoryHandle>();

  useEffect(() => {
    if (fileHandleContext) {
      setFileHandle(fileHandleContext.fileDirectoryHandle);
    }
  }, [fileHandleContext]);

  return fileHandle;
}