import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { AppProviderContext } from '../provider';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function AppRegister() {
  const authProvider = useContext(AppProviderContext);
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = new FormData(event.currentTarget);
    const data = {
      appId: form.get('appId') as string,
      // token: form.get('token') as string,
      password: form.get('password') as string,
    };
    await authProvider?.login(data);
    navigate('/', { replace: true });
  }, [authProvider, navigate]);

  return (
    <Grid
      container
      component='main'
      sx={{ height: '100vh' }}
    >
      <Grid
        item
        xs={12}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            component='h1'
            variant='h5'
          >
            App Register
          </Typography>
          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit} sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='appId'
              label='App ID'
              name='appId'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Start
            </Button>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}