import { useContext, useEffect, useReducer } from 'react';

import SelectDirectory from './SelectDirectory';
import { FileHandleContext } from '../../provider';
import { dispatchShowDirectoryPicker } from '../../actions/file-handle';
import type { FileSystemReducerTypes } from '../../reducers/file-handle';
import { fileSystemReducers, createInitialState } from '../../reducers/file-handle';

type FileHandleContainerProps = {
  children: React.ReactElement;
  setFileSystemDirectoryHandle: React.Dispatch<React.SetStateAction<FileSystemDirectoryHandle | undefined>>;
}
export default function FileHandleContainer(props: FileHandleContainerProps) {
  const { children, setFileSystemDirectoryHandle } = props;

  const fileHandle = useContext(FileHandleContext);
  const fileDirectoryHandle = fileHandle?.fileDirectoryHandle;
  const addFileDirectoryHandle = fileHandle?.addFileDirectoryHandle;
  
  const [state, dispatch] = useReducer<FileSystemReducerTypes, FileSystemDirectoryHandle | undefined>(
    fileSystemReducers,
    fileDirectoryHandle,
    createInitialState,
  );

  const isNeedSetFileDirectoryHandle = state.directory_handle === undefined;
  const handleShowDirectoryPicker = dispatchShowDirectoryPicker(dispatch, addFileDirectoryHandle);

  useEffect(() => {
    if (state.directory_handle) {
      setFileSystemDirectoryHandle(state.directory_handle);
    }
  }, [state.directory_handle]);

  // TODO error handle
  return (
    <div>
      { isNeedSetFileDirectoryHandle && <SelectDirectory handleShowDirectoryPicker={handleShowDirectoryPicker} />}
      { isNeedSetFileDirectoryHandle === false && children}
    </div>
  );
}